<template>
  <div class="row">
    <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
      <div class="card">
        <div class="card-body">
          <div class="text-center">
            <img src="/images/sorry.png" style="width: 250px" class="" />
          </div>
          <p class="font-weight-bold" style="font-size: 1.3rem">
            Due to incredibly high demand, www.digitalflu.co.uk is currently paused whilst we process a huge number of voucher requests.
          </p>
          <!-- <p class="font-weight-bold" style="font-size: 1.3rem">
            Due to the end of the flu season, www.digitalflu.co.uk will not be
            available until later in the year.
          </p> -->
          <!-- <p>
            Please try again in around 60 minutes. You can still use the
            Pharmacy Finder <a href="/find">here</a> to locate your nearest
            store and make a booking if you have a voucher. Thank you.
          </p> -->
          <p>Thank you.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
